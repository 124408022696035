
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { storeApi } from "@/services/ApiService";
import * as ST from "@/components/controls/s-table/exports";
import STable from "@/components/controls/s-table/sTable.vue";
import ItemPickerV2 from "@/components/controls/itemPickerV2.vue";
import TextEdit from "@/components/controls/text-edit.vue";
import ColorEdit from "@/components/controls/color-edit.vue";
import { DisplayMode, IsNewRow } from "@/components/controls/s-table/exports";
import { CategoryTypeEnum, DepartmentCategory, Category,IdText } from "@/api/api";
import CategoryTypeAdapter from "@/entities/CategoryTypeAdapter";

@Component({ components: { STable } })
export default class CategoriesV2 extends Vue {
  dataSource: Category[] = [];
  existingDialogVisible = false;

  public async mounted() {
    await this.loadCategories();
  }

  public async beforeRouteLeave(to: any, from: any, next: any) {
    next();
  }
  public initNewRow(): Category {
    return {
      id: -1,
      defaultColor: "#ffffff",
      name: "",
      type: CategoryTypeEnum.NUMBER_0,
      defaultDisplayType: 0,
      defaultAlphabeticalGrouping: false,
      defaultExcludeInactive: true,
      defaultAlphabeticalOrder: false,
    };
  }

  tableSettings: ST.Settings<Category> = {
    dataSource: this.dataSource,
    pagination: false,
    showNewRow: false,
    
    newRowText: "Skapa ny kategori",
    recordsUpdated: async (records) => {
      await records.map(async (c) => await this.saveCategory(c));
    },
    newRowInit: () => this.initNewRow(),
    loading: true,
    sort: {
      sortProperty: "name"
    },
    actions: {
      edit: {
        icon: "edit",
        action: (record) => {
          this.editCategory(record);
        },
        visible: (record) => !IsNewRow(record),
      },
      remove: {
        icon: "delete",
        confirmation: (record) =>
          `<b>Är du säker på att du vill ta bort ${record.name}? </b><br />
          ${this.deleteCategoryPopupInfoText(record)}`,
        action: async (record) => {
          await this.removeCategory(record);
          var recordIndex = this.dataSource.indexOf(record, 0);
          if (recordIndex > -1) {
            this.dataSource.splice(recordIndex, 1);
          }
        },
      },
    },
    columns: [
      {
        title: "Färg",
        edit: ColorEdit,
        width: 10,
        editData: (record) => ({
          editable: true,
          immediate: true,
          updateEmitter: "valueChanged",
          dataBinding: {
            propertyName: "defaultColor",
          },
          props: {
            defaultValue: record.defaultColor,
          },
        }),
      },
      {
        title: "Kategori",
        edit: TextEdit,
        width: 500,
        editData: (record) => ({
          editable: true,
          updateEmitter: "valueChanged",
          dataBinding: {
            propertyName: "name",
          },
          props: {
            defaultValue: record.name,
          },
        }),
      },
      {
        title: "Finns på",
        edit: ItemPickerV2,
        width: 220,
        editData: (record) => ({
          displayMode: DisplayMode.ATag,
          editable: false,
          dataBinding: {
            propertyName: "departments",
              formatDisplayValue: (value: IdText[]) => {
              if (value) {
                let result = value.map((tag: IdText) => tag.text);
                return result;
              }
            },
          }
        })
      },
      {
        title: "Typ",
        width: 200,
        edit: ItemPickerV2,
        editData: (record) => ({
          displayMode: DisplayMode.ATag,
          updateEmitter: "valueChanged",
          dataBinding: {
            propertyName: "type",
            formatDisplayValue: (value) =>
              CategoryTypeAdapter.fromCategoryType(value),
            parseEditValue: (value) =>
              CategoryTypeAdapter.toCategoryType(value),
          },
          info:
            record.departments?.length ?? 0 > 0
              ? "Denna kategori finns på flera avdelningar, byte av typ kommer påverka samtliga"
              : undefined,
          props: {
            dataSource: CategoryTypeAdapter.getOptions(),
            defaultOpen: !IsNewRow(record),
            defaultValue: CategoryTypeAdapter.fromCategoryType(record.type),
            placeHolder: "Välj kategorityp",
            selectionMode: "single",
          },
        }),
      },
    ],
  };
  public deleteCategoryPopupInfoText(record: Category) : string{
    if(record.type == CategoryTypeEnum.NUMBER_4)
      return 'Knutna checklistor kommer att tas bort.';
    else if(record.type == CategoryTypeEnum.NUMBER_3)
      return 'Knutna kreditkunder kommer att tas bort.';
    else if(record.type == CategoryTypeEnum.NUMBER_2)
      return 'Knutna pdf-filer kommer att tas bort.';
    return "";
  }
  public editCategory(record: Category) {
    this.$router.push({
      name: "storeEditCategory",
      params: { id: record.id.toString() },
    });
  }

  public async saveCategory(category: Category) {
    var result = await storeApi.postCategory(category);
    if (result.data) {
      category.id = result.data.id;
    }
    this.notify(category.name, "Kategori sparad");
  }
  public async removeCategory(category: Category) {
    var result = await storeApi.deleteCategory(category.id);
    this.notify(category.name, "Kategori borttagen");
  }

  public async loadCategories() {
    this.tableSettings.loading = true;
    this.dataSource.length = 0;
    var fetchResult = await storeApi.getCategories();
    this.dataSource.push(...fetchResult.data);
    this.tableSettings.loading = false;
  }
  public notify(title: string, message: string) {
    this.$notification.open({
      message: message,
      description: title,
      placement: "bottomRight",
      duration: 4,
    });
  }
}
