
import Vue from "vue";
import {Component} from "vue-property-decorator";
import colorpicker from "@/components/store/category/color.vue";
import {storeApi} from "@/services/ApiService";
import EmptyContent from "@/components/store/category/EmptyContent.vue";
import LoadingContent from "@/components/store/category/loading-content.vue";
import FreeTextContent from "@/components/store/category/FreeTextContent.vue";
import TasksContent from "@/components/store/category/tasks-content.vue";
import CreditCustomerContent from "@/components/store/category/creditcustomer-content.vue";
import ScheduleContent from "@/components/store/category/schedule-content.vue";
import WebpageContent from "@/components/store/category/webpage-content.vue";
import PdfContent from "@/components/store/category/pdf-content.vue";
import ProductContent from "@/components/store/category/product-content.vue";
import TableContent from "@/components/store/category/table-content.vue";
import itemPickerV2 from "@/components/controls/itemPickerV2.vue";
import {CategoryTypeEnum, Category, Department, IdText} from "@/api";
import CategoryTypeAdapter, {
    AdapterCategoryType,
} from "@/entities/CategoryTypeAdapter";

@Component({
    components: {
        colorpicker,
        EmptyContent,
        FreeTextContent,
        PdfContent,
        ProductContent,
        TasksContent,
        CreditCustomerContent,
        ScheduleContent,
        LoadingContent,
        itemPickerV2,
        WebpageContent,
        TableContent
    },
})
export default class EditCategory extends Vue {
    $refs!: {
        currentComponent: any;
    };
    departments: Array<Department> = new Array<Department>();
    pickedDepartments: number[] = [];
    category: Category = {
        id: -1,
        type: CategoryTypeEnum.NUMBER_0,
        name: "kategori",
        defaultAlphabeticalGrouping: false,
        defaultAlphabeticalOrder: false,
        defaultExcludeInactive: true,
        defaultDisplayType: 0,
        defaultColor: undefined,
        departments: [],
    };
    currentComponent?: any = undefined;
    confirmLoading = false;
    loading = true;

    routes: any[] = [
        {
            path: "index",
            breadcrumbName: "Kategorier",
        },
    ];

    async mounted() {
        let id = this.$route.params.id;
        let result = await storeApi.getCategory(parseInt(id));

        this.category = result.data;
        this.departments.push(...(await (await storeApi.getDepartmentsWeb()).data));

        if (this.category.departments)
            this.pickedDepartments.push(
                ...this.category.departments.map((c) => c.id ?? 0)
            );
        this.loading = false;
    }

    get mainComponent(): any | undefined {
        if (!this.category || this.category?.id == -1) return LoadingContent;
        var type = CategoryTypeAdapter.fromCategoryType(this.category.type);
        if (type == AdapterCategoryType.Products) {
            this.currentComponent = ProductContent;
        } else if (type == AdapterCategoryType.Html) {
            this.currentComponent = FreeTextContent;
        } else if (type == AdapterCategoryType.Pdf) {
            this.currentComponent = PdfContent;
        } else if (type == AdapterCategoryType.Creditcustomer) {
            this.currentComponent = CreditCustomerContent;
        } else if (type == AdapterCategoryType.Tasklist) {
            this.currentComponent = TasksContent;
        } else if (type == AdapterCategoryType.Schedule) {
            this.currentComponent = ScheduleContent;
        } else if (type == AdapterCategoryType.WebPage) {
            this.currentComponent = WebpageContent;
        } else if (type == AdapterCategoryType.Table) {
            this.currentComponent = TableContent;
        } else {
            this.currentComponent = EmptyContent;
        }
        return this.currentComponent;
    }

    get componentCategory() {
        return this.category;
    }

    get text() {
        if (!this.category) {
            return "kategori";
        }
        return this.category.name;
    }

    set text(value: string) {
        if (this.category) {
            this.category.name = value;
        }
    }

    get pin(): string {
        if (!this.category) {
            return "";
        }
        return this.category.pin ?? "";
    }

    set pin(value: string) {
        if (this.category) {
            this.category.pin = value;
        }
    }


    get visningslage() {
        return "";
    }

    set visningslage(value: any) {
    }

    get farg() {
        if (!this.category || !this.category.defaultColor) {
            return "#F3F3F3";
        }
        return this.category.defaultColor;
    }

    set farg(value: string) {
        if (this.category) {
            this.category.defaultColor = value;
        }
    }

    get selectedColor() {
        if (!this.category || !this.category.selectedColor) {
            return "#000000";
        }
        return this.category.selectedColor;
    }

    set selectedColor(value: string) {
        if (this.category) {
            this.category.selectedColor = value;
        }
    }

    validatePinInput(event: InputEvent) {
        const inputElement = event.target as HTMLInputElement;
        const filteredValue = inputElement.value.replace(/[^0-9]/g, '');
        this.pin = filteredValue;
    }

    removeSelectedColor(event: Event) {
        this.updateSelectedColor("#000000");
    }

    get typen(): CategoryTypeEnum {
        if (this.category) {
            return this.category!.type;
        }
        return CategoryTypeEnum.NUMBER_0;
    }

    set typen(value: CategoryTypeEnum) {
        this.category!.type = value;
    }

    handleUpdate(color: string) {
        this.farg = color;
    }

    updateSelectedColor(color: string) {
        this.selectedColor = color;
    }

    async Save(e: any) {
        this.confirmLoading = true;
        if (this.category) {
            if (
                this.$refs.currentComponent?.checkErrors &&
                this.$refs.currentComponent?.checkErrors().length > 0
            ) {
                this.$notification.open({
                    message: "Kunde inte spara",
                    description: this.$refs.currentComponent?.checkErrors(),
                    placement: "center",
                    duration: 4,
                });
                return;
            }
            if (this.$refs.currentComponent?.save) {
                await this.$refs.currentComponent?.save(this.category);
            }
            await storeApi.postCategory(this.category);
            this.confirmLoading = false;

            this.$notification.open({
                message: "Sparat",
                description: "Kategorin " + this.category.name + " har sparats",
                placement: "bottomRight",
                duration: 4,
            });
        }
        this.$router.go(-1);
    }
}
