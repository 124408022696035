
import Vue from "vue";
import moment from "moment";
const PDFJS = require("pdfjs-dist/webpack");
import { Component, Prop } from "vue-property-decorator";
import { storeApi } from "@/services/ApiService";
import { PostPdfRequest, Category } from "@/api/api";

@Component({
  components: {
    PDFJS,
  },
})
export default class PdfContent extends Vue {
  $refs!: {
    thecanvas1: HTMLCanvasElement;
    thecanvas2: HTMLCanvasElement;
    conversionCanvas: HTMLCanvasElement;
    fileupload: HTMLInputElement;
    fileuploadfuture: HTMLInputElement;
  };

  @Prop()
  category?: Category;

  pdfImageList!: any[];
  futurePdfImageList!: any[];
  futurePdfPublishDate: any = null;
  futurePdfExist: boolean = false;

  uploadingCurrent = false;
  uploadingFuture = false;

  result: any;
  init: boolean = true;
  update: number = 0;
  test: boolean = true;

  deletePdf: boolean = false;
  deleteFuture: boolean = false;

  loadmodalvisible: boolean = false;

  get isInitial() {
    return this.init;
  }

  get isSaving() {
    return false;
  }

  async mounted() {
    var result = await storeApi.getPdfPreview(this.category!.id);
    if (result.data.pdf) {
      var img = new Image();
      img.src = result.data.pdf;
      img.onload = () => {
        let canvas = this.$refs.thecanvas1;
        let context = canvas.getContext("2d");
        canvas.width = 404;
        canvas.height = 600;
        context!.drawImage(img, 0, 0, canvas.width, canvas.height);
      };
    }
    if (result.data.unpublishedPdf) {
      this.futurePdfExist = true;
      var img2 = new Image();
      img2.src = result.data.unpublishedPdf;
      img2.onload = () => {
        let canvas = this.$refs.thecanvas2;
        let context = canvas.getContext("2d");
        canvas.width = 404;
        canvas.height = 600;
        context!.drawImage(img2, 0, 0, canvas.width, canvas.height);
      };
      if (result.data.publicationDate) {
        this.futurePdfPublishDate = moment(
          result.data.publicationDate,
          "YYYY-MM-DD"
        );
      }
    }
  }

  private async getPdfImageList(loadpdf: any) {
    let pdfImageList = [];
    let canvas = this.$refs.conversionCanvas;
    let conversionContext = canvas.getContext("2d");

    for (let i = 0; i < loadpdf.numPages; i++) {
      let page = await loadpdf.getPage(i + 1);

      var viewport = await page.getViewport({ scale: 1 });

      var hscale = 1188 / viewport.height;
      var wscale = 840 / viewport.width;
      let newscale = hscale > wscale ? hscale : wscale;

      var viewport1 = await page.getViewport({ scale: newscale });
      canvas.width = viewport1.width;
      canvas.height = viewport1.height;

      await page.render({
        canvasContext: conversionContext,
        viewport: viewport1,
      });
      let dataurl = canvas.toDataURL("image/jpg", 0.7);
      pdfImageList.push(dataurl);
    }
    return pdfImageList;
  }

  filesChange(fieldName: any, fileList: any) {
    if (fileList.length == 0) {
      return;
    }

    var fileReader = new FileReader();
    fileReader.onload = async (e: any) => {
      this.uploadingCurrent = true;
      this.result = e.result;

      let loadpdf = await PDFJS.getDocument(fileReader.result).promise;

      this.pdfImageList = await this.getPdfImageList(loadpdf);

      let context = this.$refs.thecanvas1.getContext("2d");
      let page = await loadpdf.getPage(1);

      var viewport = await page.getViewport({ scale: 1 });
      let newScale = this.$refs.thecanvas1.width / viewport.width;

      viewport = await page.getViewport({ scale: newScale });
      this.$refs.thecanvas1.width = viewport.width;
      this.$refs.thecanvas1.height = viewport.height;

      await page.render({ canvasContext: context, viewport: viewport });

      this.uploadingCurrent = false;
    };
    fileReader.readAsArrayBuffer(fileList[0]);
  }

  async handleRemove(deleteFuture: boolean) {
    let canvas = null;
    if (deleteFuture) {
      this.$refs.fileuploadfuture.value = "";
      canvas = this.$refs.thecanvas2;
      this.futurePdfImageList = [];
      this.futurePdfExist = false;
    } else {
      this.$refs.fileupload.value = "";
      this.pdfImageList = [];
      canvas = this.$refs.thecanvas1;
    }
    let context = canvas.getContext("2d");
    context?.clearRect(0, 0, canvas.width, canvas.height);

    this.deletePdf = !deleteFuture;
    this.deleteFuture = deleteFuture;
  }

  futureFilesChange(fieldName: any, fileList: any) {
    if (fileList.length == 0) {
      return;
    }

    var fileReader = new FileReader();
    fileReader.onload = async (e: any) => {
      this.uploadingFuture = true;
      this.result = e.result;

      let loadpdf = await PDFJS.getDocument(fileReader.result).promise;

      let context = this.$refs.thecanvas2.getContext("2d");
      let page = await loadpdf.getPage(1);

      var viewport = await page.getViewport({ scale: 1 });
      let newScale = this.$refs.thecanvas2.width / viewport.width;

      viewport = await page.getViewport({ scale: newScale });
      this.$refs.thecanvas2.width = viewport.width;
      this.$refs.thecanvas2.height = viewport.height;

      await page.render({ canvasContext: context, viewport: viewport });

      this.futurePdfImageList = [];
      for (let i = 0; i < loadpdf.numPages; i++) {
        let canvas = this.$refs.conversionCanvas;
        let conversionContext = canvas.getContext("2d");
        let page = await loadpdf.getPage(i + 1);

        viewport = await page.getViewport({ scale: 1 });

        var hscale = 1188 / viewport.height;
        var wscale = 840 / viewport.width;
        let newscale = hscale > wscale ? hscale : wscale;

        var viewport1 = await page.getViewport({ scale: newscale });
        canvas.width = viewport1.width;
        canvas.height = viewport1.height;

        await page.render({
          canvasContext: conversionContext,
          viewport: viewport1,
        });
        let dataurl = canvas.toDataURL("image/jpg", 0.7);
        this.futurePdfImageList.push(dataurl);
      }
      this.uploadingFuture = false;
      this.futurePdfExist = true;
    };

    fileReader.readAsArrayBuffer(fileList[0]);
  }
  onDateChange(date: moment.Moment, dateString: any) {
    this.futurePdfPublishDate = date;
  }

  checkErrors(): string {
    if (
      this.futurePdfExist &&
      (!this.futurePdfPublishDate || this.futurePdfPublishDate === "")
    ) {
      return "Inget publiceringsdatum är satt för fratimda pdf";
    }
    return "";
  }

  async save(category: Category) {
    this.loadmodalvisible = true;
    if (this.category?.id && (this.deletePdf || this.deleteFuture)) {
      await storeApi.deletePdf({
        categoryId: this.category?.id,
        deletePdf: this.deletePdf,
        deleteFuture: this.deleteFuture,
      });
    }

    await storeApi.postPdf({categoryId: category.id, pdf: this.pdfImageList, futurePdf: this.futurePdfImageList, publishDate: this.futurePdfPublishDate});

    this.loadmodalvisible = false;
  }
}
